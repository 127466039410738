/*
 * Copyright © 2021 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

export const ENV_NAME: string = 'prod'
export const GXC_HOST: string = 'https://gx.games'
export const STORE_HOST: string = 'https://store.gx.me'

// APIs.
export const GMX_API: string = 'https://bo.api.gmx.dev'
export const STORE_API: string = 'https://api.gx.me'
export const DYNAMIC_CONFIG_API: string = 'https://api.config.opr.gg'

export const REACT_APP_VERSION = process.env.REACT_APP_VERSION

// Report errors to Sentry.
export const SENTRY_ENABLED: boolean = true
// Sentry settings.
export const SENTRY_DSN: string =
  'https://10d82c666d044dcd8949fdc18ce2d71d@sentry-relay.opera-api.com/444'

// Show/hide internal errors.
export const INTERNAL_ERRORS_ENABLED: boolean = false

// Show dev tools.
export const OPERA_DEV_TOOLS: boolean = false

// Show a specific header color for different envs.
export const HEADER_COLOR: string = '#2eca16'
