/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { queryOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { api, handleResponse } from '../core'
import { type GetAlertsParams, type UpdateSystemAlertStatusRequest } from '../generated/Api'

export const alertsQueries = {
  keyPrefix: () => ['alerts'] as const,
  list: {
    keyPrefix: () => [...alertsQueries.keyPrefix(), 'list'] as const,
    options: (params: GetAlertsParams) =>
      queryOptions({
        queryFn: ({ queryKey: [_scope, _entity, params], signal }) =>
          handleResponse(api.alerts.getAlerts(params, { signal })),
        queryKey: [...alertsQueries.list.keyPrefix(), params] as const,
        refetchOnWindowFocus: true,
      }),
  },
  useUpdateAlert() {
    const queryClient = useQueryClient()
    return useMutation({
      mutationFn: ({ alertId, ...params }: UpdateSystemAlertStatusRequest & { alertId: string }) =>
        handleResponse(api.alerts.resolveAlert(alertId, params)),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: alertsQueries.keyPrefix() })
        toast.success(`Alert updated`)
      },
    })
  },
}
